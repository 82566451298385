import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useState, useEffect} from "react";

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  > label {
    font-weight: bold; /* Adjust the font-weight as needed */
    margin-bottom: 0.5rem;
    display: block;
  }

`;

const Select = styled.select`
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
  border-style: none none solid;

  &:focus {
    outline: none;
  }

  > h4 {
    margin-bottom: 1rem;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  label {
    display: flex;
    align-items: center;
    margin-bottom: 8px; /* Add space between each checkbox item */
  }

  input[type="checkbox"] {
    margin-right: 8px; /* Add space between checkbox and label */
  }
`;

/**
 * Label used mainly in the banner to mark a distribution as latest.
 *
 * @param tagValue
 * @returns {*}
 * @constructor
 */
const Filter = ({tagValue, filterTypes, changeFunction, filterName, initialValue, fontSize, backgroundColor, inputType}) => {
  const font = '"DM Sans", sans-serif';
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [selectedValues, setSelectedValues] = useState(initialValue);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    changeFunction(filterName, value);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const updatedValues = event.target.checked
      ? [...selectedValues, value]
      : selectedValues.filter((v) => v !== value);
    setSelectedValues(updatedValues);
    changeFunction(filterName, updatedValues);
  };

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      color="#ffffff"
      font={font}
      fontSize={fontSize}
      top="-.3rem"
    >
      <label htmlFor={'type'}>
        {tagValue}
      </label>
      {inputType === 'dropdown' ? (
      <Select
        id={'type'}
        value={selectedValue}
        // onChange={(event) => changeFunction(event.target?.value)}
        onChange={handleSelectChange}
      >

        {/*<option value="" disabled>{selectedValue}</option>*/}
        {Object.entries(filterTypes).map(([key, value]) => (
          <option key={key} value={key}>{value}</option>
        ))}
      </Select>
        ) : (
        <CheckboxContainer>
          {Object.entries(filterTypes).map(([key, value]) => (
            <label key={key}>
              <input
                type="checkbox"
                value={key}
                checked={selectedValues.includes(key)}
                onChange={handleCheckboxChange}
              />
              {value}
            </label>
          ))}
        </CheckboxContainer>
      )}
    </Wrapper>
  );
};

Filter.propTypes = {
  /** Content of the label */
  tagValue: PropTypes.node.isRequired,
  filterTypes: PropTypes.object.isRequired,
  filterName: PropTypes.string,
  changeFunction: PropTypes.func,
  fontSize: PropTypes.node,
  backgroundColor: PropTypes.node
};

export default Filter;
